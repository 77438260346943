import { BreadcrumbJsonLd, LogoJsonLd, NextSeo } from 'next-seo';
import NextHead from 'next/head';
import React from 'react';

import { InternalExternalLink } from '@/components/Link/InternalExternalLink';

import { getBreadCrumbForPath } from '../../helpers/sitemap/getBreadCrumbForPath';
import { getResponsiveImageUrl } from '../images/ResponsiveImage';

export const Seo = ({ config, page, sitemap, preview, sitemapItem }) => {
  if (!config || !config?.url || !page || !sitemap || !sitemapItem) return null;

  const pagePath = sitemapItem.path;

  const baseUrl = config?.url;
  const seoTitle = `${preview ? '👀 Preview mode - ' : ''}${
    page?.seoFields?.seo?.seo_title ?? page.title
  }`;
  const seoDescription =
    page?.seoFields?.seo?.meta_description ||
    page?.description ||
    config?.description;
  const seoCanonical = `${baseUrl}${pagePath}`;

  const hero = page?.sections?.find(
    ({ _type }) => _type === 'hero' || _type === 'articleHeader',
  );

  const seoImageObj = page.seoImage || hero?.image || config.seoImage;

  const seoImage = {
    url: getResponsiveImageUrl({
      src: seoImageObj?.src,
      width: 1200,
      height: 630,
      crop: seoImageObj?.crop,
      hotspot: seoImageObj?.hotspot,
    }),
    width: 1200,
    height: 630,
    alt: seoImageObj?.alt || '',
  };

  const brandJsonLd = {
    '@context': 'https://schema.org',
    '@type': 'Brand',
    name: config?.title,
    description: config?.description,
    logo: `${baseUrl}/logo.svg`,
    URL: baseUrl,
    sameAs: config?.socialLinks,
  };

  const breadCrumb = getBreadCrumbForPath(pagePath, sitemap);

  const twitterHandle = page?.seoFields?.twitterAuthor || config.twitterAuthor;
  const twitterSite = page?.seoFields?.twitterSite || config.twitterSite;

  return (
    <>
      <NextSeo
        title={seoTitle}
        noindex={sitemapItem.disallowRobots}
        nofollow={sitemapItem.disallowRobots}
        titleTemplate={`${config.title} | %s`}
        description={seoDescription}
        canonical={seoCanonical}
        openGraph={{
          type: 'website',
          url: seoCanonical,
          title: seoTitle,
          description: seoDescription,
          images: [seoImage],
          site_name: config.title,
        }}
        twitter={{
          handle: twitterHandle,
          site: twitterSite,
          cardType: 'summary_large_image',
        }}
      />

      {preview && (
        <SeoPreview
          seoTitle={seoTitle}
          seoDescription={seoDescription}
          seoCanonical={seoCanonical}
          seoImage={seoImage}
          seoImageObj={seoImageObj}
          breadCrumb={breadCrumb}
          twitterSite={twitterSite}
          twitterHandle={twitterHandle}
          brandJsonLd={brandJsonLd}
        />
      )}

      <LogoJsonLd logo={`${baseUrl}/logo.svg`} url={baseUrl} />

      {Boolean(breadCrumb?.length) && (
        <BreadcrumbJsonLd
          itemListElements={breadCrumb.map(({ title, path }, index) => ({
            position: index + 1,
            name: title,
            item: `${baseUrl}${path}`,
          }))}
        />
      )}

      <NextHead>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(brandJsonLd, null, 2) }}
        />
      </NextHead>
    </>
  );
};

const SeoPreview = (props) => (
  <div
    style={{
      position: 'fixed',
      right: 6,
      top: 90,
      zIndex: 9998,
      background: 'white',
      border: '1px solid #111',

      fontSize: 10,
      maxWidth: 227,
      lineHeight: 1.5,
    }}
  >
    <span
      style={{
        display: 'block',
        position: 'absolute',
        left: 4,
        top: 4,
        textTransform: 'uppercase',
        fontSize: 10,
        background: 'white',
        padding: 3,
        fontWeight: 600,
        border: '1px solid rgba(0,0,0,.5)',
      }}
    >
      SEO
    </span>
    <div>
      {props.seoImage.url && (
        <img
          src={props.seoImage.url
            .replace('w=1200', 'w=240')
            .replace('h=630', 'h=126')}
          alt=""
          style={{ width: 'auto' }}
        />
      )}
    </div>
    <div style={{ display: 'flex', gap: 2, flexDirection: 'column', padding: 8 }}>
      <strong style={{ fontWeight: 600 }}>
        {props.seoTitle.replace('👀 Preview mode - ', '')}
      </strong>
      <p>{props.seoDescription}</p>
      <hr />
      <p>
        <a
          href={props.seoCanonical}
          style={{
            color: 'blue',
            textDecoration: 'underline',
            wordBreak: 'break-all',
          }}
        >
          {props.seoCanonical}
        </a>
      </p>

      <hr />

      <div>
        /{' '}
        {props.breadCrumb.map(({ title, path }, index) => (
          <span key={index}>
            <InternalExternalLink href={`${path}`}>{title}</InternalExternalLink> /{' '}
          </span>
        ))}
      </div>

      <hr />

      <p>
        › {props.twitterHandle}
        <br />› {props.twitterSite}
      </p>
    </div>
  </div>
);
