import imageUrlBuilder from '@sanity/image-url';
import Head from 'next/head';
import NextImage from 'next/image';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { roundToNearest } from '../../helpers/utils/number';
import { useDebounce } from '../../hooks/useDebounce';

export const imageBuilder = imageUrlBuilder({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
});

const IMAGE_QUALITY = 75;

export const ResponsiveImage = ({
  src,
  width,
  height,
  crop,
  hotspot,
  alt = '',
  layout = 'intrinsic',
  objectFit,
  className,
  preventResize = false,
  priority,
  ratio,
  roundSize = 0,
  asset,
}) => {
  const wrapperRef = useRef(null);

  const [responsiveSrc, setResponsiveSrc] = useState(null);
  const [state, setState] = useState('loading');

  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const debouncedWrapperWidth = useDebounce(wrapperWidth, 500);
  const debouncedWrapperHeight = useDebounce(wrapperHeight, 500);

  if (typeof asset === 'object' && asset?._type == 'reference') {
    src = imageBuilder.image(asset._ref).url();
  }

  const originalDimensions = getOriginalImageDimensions(src);
  if (!width) width = originalDimensions?.width;
  if (!height) height = originalDimensions?.height;
  const aspectRatio = originalDimensions?.aspectRatio;

  const onResize = useCallback(() => {
    if (!src) return;
    if (!wrapperRef?.current) return;
    if (typeof src !== 'string') return;

    const rect = wrapperRef.current.getBoundingClientRect();

    const w = roundToNearest(roundSize, rect.width);
    const h = roundSize;

    setWrapperWidth(w);
    setWrapperHeight(h);
  }, [src, roundSize]);

  /**
   * Listen to window resizes
   */

  useEffect(() => {
    if (!src) return;
    if (!wrapperRef?.current) return;
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(wrapperRef.current);
    () => resizeObserver.disconnect();
  }, [wrapperRef, src, onResize]);

  /**
   * when the debounced window width changes
   * generate new source for sanity images
   */

  useEffect(() => {
    if (!src) return;
    if (typeof src !== 'string') return;

    const rect = wrapperRef.current.getBoundingClientRect();

    const newWidth = roundToNearest(roundSize, rect.width);
    let newHeight = roundToNearest(roundSize, rect.height);
    let quality = IMAGE_QUALITY;

    if (preventResize === true) {
      // Do we need to factor in crops as well? https://github.com/sanity-io/hydrogen-sanity-demo/blob/main/src/components/SanityImage.client.jsx#L100
      newHeight = rect.width / (+width / +height);
      quality = null;
    }

    // for layouts where height depends on the image, use image ratio
    if (newHeight === 0) newHeight = newWidth / (+width / +height);

    setResponsiveSrc(
      getResponsiveImageUrl({
        src,
        width: newWidth,
        height: newHeight,
        hotspot,
        crop,
        quality,
      }),
    );
  }, [
    wrapperWidth,
    debouncedWrapperHeight,
    width,
    height,
    src,
    crop,
    hotspot,
    preventResize,
    roundSize,
  ]);

  const onImageLoad = useCallback(() => {
    setState('loaded');
  }, []);

  if (!src) return null;

  const styleObj = {
    fontSize: 0,
    height: '100%',
    width: '100%',
  };

  if (!objectFit) styleObj.position = 'relative';
  if (ratio) styleObj.aspectRatio = ratio;

  return (
    // disable margin underneath next image until classes can be applied to next image directly
    // https://github.com/vercel/next.js/discussions/22861
    <div style={styleObj} ref={wrapperRef}>
      {priority && responsiveSrc && (
        <Head>
          <link
            rel="preload"
            as="image"
            href={`${responsiveSrc}&fit=max&q=${IMAGE_QUALITY}`}
          />
        </Head>
      )}
      {responsiveSrc && (
        <NextImage
          src={responsiveSrc}
          className={className}
          alt={alt}
          layout={layout}
          width={layout === 'fill' ? null : width}
          height={layout === 'fill' ? null : height}
          objectFit={objectFit}
          priority={priority}
          loader={({ src, quality = 100 }) => {
            return `${src}&fit=max&q=${quality}`;
          }}
          quality={75}
          onLoadingComplete={onImageLoad}
        />
      )}
    </div>
  );
};

export const ResponsiveImageMemo = React.memo(ResponsiveImage);

export function getResponsiveImageUrl({
  src,
  width,
  height,
  crop,
  hotspot,
  blur = 0,
  quality = IMAGE_QUALITY,
  asset,
}) {
  if (asset && typeof asset === 'object' && asset?._type == 'reference') {
    src = imageBuilder.image(asset._ref).url();
  }
  if (!src) return null;
  const dpr = typeof window === 'undefined' ? 1 : window.devicePixelRatio || 1;
  let newSrc = imageBuilder.image(src).auto('format');
  if (quality) newSrc = newSrc.quality(IMAGE_QUALITY);
  if (blur) newSrc = newSrc.blur(blur);
  if (width) newSrc = newSrc.width(Math.ceil(width * dpr));
  if (height) newSrc = newSrc.height(Math.ceil(height * dpr));
  if (hotspot?.x && hotspot?.y)
    newSrc = newSrc.crop('focalpoint').focalPoint(hotspot.x, hotspot.y).fit('crop');
  if (crop?.left && crop?.top && crop?.right && crop?.top)
    newSrc = newSrc.rect(crop.left, crop.top, crop.right, crop.top);

  return newSrc.url();
}

export function getOriginalImageDimensions(src) {
  if (!src) return { width: 0, height: 0, aspectRatio: 0 };
  const filename = src?.split('/')?.pop();
  const pattern = /-(\d+x\d+)/;
  const [, dimensions] = pattern.exec(filename);
  const [width, height] = dimensions.split('x').map((v) => parseInt(v, 10));
  const aspectRatio = width / height;
  return {
    width,
    height,
    aspectRatio,
  };
}
