import { getItemForPath } from './getItemForPath';

export const getBreadCrumbForPath = (path, sitemap) => {
  const pathParts = path.split('/');

  return path
    .split('/')
    .slice(1)
    .map((x, i) =>
      getItemForPath(`/${pathParts.slice(1, i + 2).join('/')}`, sitemap),
    )
    .filter(Boolean);
};
